import { createBrowserRouter } from 'react-router-dom'

export const Routes = {
	Root: {
		id: 'root',
		path: '/',
		toString() {
			return this.path
		},
	},
	SignIn: {
		id: 'sign-in',
		path: '/sign-in',
		toString() {
			return this.path
		},
	},
	SignOut: {
		id: 'sign-out',
		path: '/sign-out',
		toString() {
			return this.path
		},
	},
	Onboarding: {
		id: 'onboarding',
		path: '/ob',
		toString() {
			return this.path
		},
	},
	OnboardingStart: {
		id: 'onboarding-start',
		path: '/ob/start',
		toString() {
			return this.path
		},
	},
	OnboardingCheckout: {
		id: 'onboarding-checkout',
		path: '/ob/:websiteId/checkout',
		toString() {
			return this.path
		},
	},
	OnboardingSuccess: {
		id: 'onboarding-success',
		path: '/ob/:websiteId/success',
		toString() {
			return this.path
		},
	},
}

export const router = createBrowserRouter([
	{
		...Routes.SignIn,
		caseSensitive: false,
		async lazy() {
			const { SignIn, loader, action } = await import('./pages/sign-in')
			return { Component: SignIn, loader, action }
		},
	},
	{
		...Routes.SignOut,
		caseSensitive: false,
		async lazy() {
			const { loader, action } = await import('./pages/sign-out')
			return { loader, action }
		},
	},
	{
		...Routes.Root,
		lazy: async () => {
			const { loader } = await import('./pages/root')
			return { loader }
		},
		children: [
			{
				...Routes.Onboarding,
				lazy: async () => {
					const { loader } = await import('./pages/onboarding-0')
					return { loader }
				},
				children: [
					{
						...Routes.OnboardingStart,
						async lazy() {
							const { OnboardingStart, action } = await import('./pages/onboarding-1-start')
							return { Component: OnboardingStart, action }
						},
					},
					{
						...Routes.OnboardingCheckout,
						async lazy() {
							const { OnboardingCheckout } = await import('./pages/onboarding-2-checkout')
							return { Component: OnboardingCheckout }
						},
					},
				],
			},
		],
	},
])
