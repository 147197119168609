import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { browserPopupRedirectResolver, browserSessionPersistence, debugErrorMap, initializeAuth } from 'firebase/auth'

export const fApp = initializeApp({
	apiKey: 'AIzaSyDKIDj25oF26rWrVJVcd_1m8oucUtijgjg',
	authDomain: 'app.gloc.al',
	projectId: 'g-local',
	storageBucket: 'g-local.appspot.com',
	messagingSenderId: '180032085461',
	appId: '1:180032085461:web:b9ec32577d00961bdaf15c',
	measurementId: 'G-X5JBDKTYH5',
})

export const fAuth = initializeAuth(fApp, {
	errorMap: debugErrorMap,
	persistence: browserSessionPersistence,
	popupRedirectResolver: browserPopupRedirectResolver,
})

export const fAnalytics = getAnalytics(fApp)

export const fAppCheck = initializeAppCheck(fApp, {
	provider: new ReCaptchaV3Provider('6Ldoy8glAAAAAGpkW0aU5ztmphndZBfUZkvWHXu-'),
	isTokenAutoRefreshEnabled: true,
})
