import './firebase'

import React from 'react'
import { RouterProvider } from 'react-router-dom'

import ReactDOM from 'react-dom/client'

import { router } from '~/router'
import './index.css'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.Suspense fallback={<p> Loading...</p>}>
		<RouterProvider router={router} />
	</React.Suspense>
)
